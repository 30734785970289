<template>
    <div class="wrapper -historia padding-menu">
        <div class="contenedor-vistas">
            <section class="vista-historia -inicio column-center" :notvisible="!init">
                <h1 v-html="$t('our_history.init_view.title')"></h1>
                <a @click="inithistory" class="link-arrow">{{ $t('our_history.init_view.action') }}</a>
            </section>

            <section class="vista-historia -year-1857 -padding-selector" :active="actual == 0" :past="actual > 0">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.1875_view.title') }}</h2>
                        <div v-html="$t('our_history.1875_view.text')"></div>
                    </div>
                    <img :src="$t('our_history.1875_view.sub_img')" alt="1875" class="imagen-1">
                    <div class="slider">
                        <slick ref="slick" :options="slickOptions">

                            <div class="slide" :key="key" :n="n" v-for="(n, key) in 13">
                                <div class="contenedor-imagen">
                                    <img :src="`/img/historia/slide-1875-${n}.png`" alt="">
                                </div>
                            </div>


                        </slick>
                    </div>
                    <a @click="reveal_1857_1 = true" class="reveal-link -first">
                        {{ $t('our_history.1875_view.action') }}
                    </a>
                </div>
                <div class="green-cover row-center" v-if="reveal_1857_1">
                    <div class="curiosidad popup_1857_1 row-start">
                        <div class="texto">
                            <a class="cerrar" @click="reveal_1857_1 = false">✕</a>
                            <div v-html="$t('our_history.1875_view.modal_text')"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-1975 -padding-selector" :active="actual == 1" :past="actual > 1">
                <div class="container row-center">
                    <div class="green-box">
                        <h2 class="mb0">{{ $t('our_history.1975_view.title') }}</h2>
                        <div v-html="$t('our_history.1975_view.text_1')"></div>
                        <div v-if='show_1975' v-html="$t('our_history.1975_view.text_2')"></div>
                        <a :class="!show_1975 ? 'flecha-white-down' : 'flecha-white-down up'"
                            @click="show_1975 = !show_1975"> {{ (show_1975) ? $t('show_less') : $t('show_more') }}</a>
                    </div>
                    <!-- <a @click="reveal_1957_1 = true" class="reveal-link -first">{{ $t('our_history.1975_view.action') }}</a> -->
                </div>
                <div class="green-cover row-center" v-if="reveal_1957_1">
                    <div class="curiosidad row-start">
                        <a class="cerrar" @click="reveal_1957_1 = false">✕</a>
                        <h3>{{ $t('our_history.1975_view.modal_title') }}</h3>
                        <div class="texto" v-html="$t('our_history.1975_view.modal_text')"></div>
                        <div class="contenedor-imagen">
                            <img :src="$t('our_history.1975_view.modal_img')" alt="Curiosidad">
                        </div>
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-1988 -padding-selector" :active="actual == 2" :past="actual > 2">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.1988_view.title') }}</h2>
                        <div v-html="$t('our_history.1988_view.text')"></div>
                        <img :src="$t('our_history.1988_view.logo_1')" class="imagen-2" alt="logo Viscofan">
                        <img :src="$t('our_history.1988_view.logo_2')" class="imagen-2" alt="logo Comar">
                    </div>
                    <div class="imagen-1">
                        <img :src="$t('our_history.1988_view.sub_img')" alt="">
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-1989 -padding-selector" :active="actual == 3" :past="actual > 3">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.1989_view.title') }}</h2>
                        <div v-html="$t('our_history.1989_view.text')"></div>
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-1998 -padding-selector" :active="actual == 4" :past="actual > 4">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.1998_view.title') }}</h2>
                        <div v-html="$t('our_history.1998_view.text')"></div>
                    </div>
                    <img :src="$t('our_history.1998_view.sub_img')" alt="1998" class="imagen-1">
                    <a @click="reveal_1998_1 = true" class="reveal-link -first">{{ $t('our_history.1998_view.action') }}</a>
                    <div class="green-cover row-center" v-if="reveal_1998_1">
                        <div class="curiosidad row-start">
                            <a class="cerrar" @click="reveal_1998_1 = false">✕</a>
                            <h3>{{ $t('our_history.1998_view.modal_title') }}</h3>
                            <iframe :src="$t('our_history.1998_view.modal_iframe')" title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-2005 -padding-selector" :active="actual == 5" :past="actual > 5">
                <div class="container row-start">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2005_view.title') }}</h2>
                        <div v-html="$t('our_history.2005_view.text')"></div>
                    </div>
                    <img :src="$t('our_history.2005_view.sub_img')" alt="2004" class="imagen-1">
                </div>
            </section>

            <section class="vista-historia -year-2015 -padding-selector" :active="actual == 6" :past="actual > 6">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2015_view.title') }}</h2>
                        <div v-html="$t('our_history.2015_view.text')"></div>
                        <img :src="$t('our_history.2015_view.logo_1')" alt="logo Portobello" class="imagen-2">
                        <img :src="$t('our_history.2015_view.logo_2')" alt="logo Taboada" class="imagen-2">
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-2016 -padding-selector" :active="actual == 7" :past="actual > 7">
                <div class="container row-between">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2016_view.title') }}</h2>
                        <div v-html="$t('our_history.2016_view.text')"></div>
                        <img :src="$t('our_history.2016_view.logo_1')" alt="logo Norenense" class="logo-norenense">
                    </div>
                </div>
                <div class="contenedor-imagen">
                    <img :src="$t('our_history.2016_view.sub_img')" alt="2016" class="imagen-1">
                    <h2 class="main-title">{{ $t('our_history.2016_view.sub_title') }}</h2>
                </div>
            </section>

            <section class="vista-historia -year-2020" :active="actual == 8" :past="actual > 8">
                <div class="container row-center"
                    :style="`background-image: url(${$t('our_history.2020_view.background')});`">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2020_view.title') }}</h2>
                        <div v-html="$t('our_history.2020_view.text')"></div>
                    </div>
                </div>
                <a :href="$t('our_history.2020_view.link')" target="_blank" class="enlace-cover"></a>
            </section>

            <section class="vista-historia -year-2021" :active="actual == 9" :past="actual > 9">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2021_view.title') }}</h2>
                        <div v-html="$t('our_history.2021_view.text')"></div>
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-2023" :active="actual == 10" :past="actual > 10">
                <div class="container row-center">
                    <div class="cont-green">
                        <img :src="$t('our_history.2023_view.sub_img_1')" alt="" class="img-circle">
                        <div class="green-box">
                            <h2>{{ $t('our_history.2023_view.title') }}</h2>
                            <div v-html="$t('our_history.2023_view.text')"></div>
                        </div>
                        <img class="images-sostenibilidad" :src="$t('our_history.2023_view.sub_img_2')">
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-2024 -padding-selector" :active="actual == 11" :past="actual > 11">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2024_view.title') }}</h2>
                        <div v-html="$t('our_history.2024_view.text')"></div>
                        <img :src="$t('our_history.2024_view.logo_1')" class="imagen-2" alt="logo Viscofan">
                    </div>
                    <div class="imagen-1">
                        <h3 v-if="$t('our_history.2024_view.text_img')"> {{ $t('our_history.2024_view.text_img') }}</h3>
                        <img :src="$t('our_history.2024_view.sub_img')" alt="">
                    </div>
                </div>
            </section>

            <section class="vista-historia -year-2025 -padding-selector" :active="actual == 12" :past="actual > 12">
                <div class="container row-center">
                    <div class="green-box">
                        <h2>{{ $t('our_history.2025_view.title') }}</h2>
                        <div class="text">
                            <p v-html="$t('our_history.2025_view.text')"></p>
                            <a href="https://www.carretilla.info/historia" target="_blank" class="">
                                {{ $t('our_history.2025_view.btn') }}
                            </a>
                        </div>
                        <!--                         <img :src="$t('our_history.2025_view.logo_1')" class="imagen-2" alt="logo ian50">
 -->
                        <div class="imagen-1">
                            <img :src="$t('our_history.2025_view.logo_1')" class="imagen-2" alt="logo ian50">
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <div class="selector-years-container" :last="actual == 12">
            <a @click="prevyear" class="prev-arrow"></a>
            <div class="years-view">
                <nav class="selector-years row-start" :style="`transform:translateX(${distance}px)`">
                    <a @click="actual = key" :notactual="years[actual] != year" v-for="(year, key) in years" :key="key"
                        class="year">{{ year }}</a>
                </nav>
            </div>
            <a v-if="actual != 12" @click="nextyear" class="next-arrow"></a>
        </div>
    </div>
</template>

<script>

import Slick from 'vue-slick';

export default {
    name: 'historia',
    data: () => ({
        init: true,
        // actual:10,
        actual: -1,
        years: [1875, 1975, 1988, 1989, 1998, 2005, 2015, 2016, 2020, 2022, 2023, 2024, 2025],
        reveal_1857_1: false,
        reveal_1857_2: false,
        reveal_1957_1: false,
        reveal_1998_1: false,
        reveal_2016_1: false,
        show_1975: false,
        slickOptions: {
            slidesToShow: 1,
            arrows: true,
            dots: false,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true
        },
    }),
    methods: {
        inithistory() {
            this.actual++;
            this.init = false;
        },
        nextyear() {
            this.actual++;
        },
        prevyear() {
            if (this.actual != 0) {
                this.actual--;
            } else {
                this.init = true;
                this.actual = -1;
            }
        }
    },
    computed: {
        distance() {

            let distance;

            if (window.innerHeight > 750) {
                distance = -210 * this.actual;
            } else {
                distance = -105 * this.actual;
            }

            return distance;
        }
    },
    components: {
        Slick
    },
}
</script>