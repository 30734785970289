<template>
  <div class="wrapper -nuestras-marcas padding-menu -fondo-campo">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link :to="$route.path">{{
          $t("navbar_menu.our_brands.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page nuestras-marcas">
      <div class="container">
        <h1>{{ $t("our_brands.title") }}</h1>
        <div class="green-box" v-html="$t('our_brands.main_banner.text')"></div>
        <div class="contenedor-imagen row-end">
          <img
            :src="$t('our_brands.main_banner.img')"
            alt="img main banner"
            class="img-head"
          />
        </div>
      </div>
    </section>

    <section class="banner-carretilla">
      <div class="container row-end">
        <img :src="$t('our_brands.carretilla_150.img')" alt="" />
        <div class="green-box">
          <h2>{{ $t("our_brands.carretilla_150.title") }}</h2>
          <div v-html="$t('our_brands.carretilla_150.text')"></div>
          <a
            href="https://www.carretilla.info/"
            target="_blank"
            class="arrow-link"
          >
            {{ $t("our_brands.carretilla_150.btn_text") }}
          </a>
        </div>
      </div>
    </section>

    <section class="categorias-marcas">
      <div class="container">
        <h2 class="main-title">
          {{ $t("our_brands.other_brands_banner.title") }}
        </h2>
      </div>
      <div class="container row-start">
        <article
          class="categoria-marca"
          v-for="(category, key) in $t(
            'our_brands.other_brands_banner.categories'
          )"
          :key="key"
        >
          <img
            :src="`/img/nuestras-marcas-${category.id}.png`"
            :alt="category.name"
          />
          <span
            class="background"
            :style="
              `background:url(/img/background-nuestras-marcas-${category.id}.jpg) center/cover no-repeat`
            "
          >
          </span>
          <h2 class="link-arrow" v-html="category.name" @click="showtext"></h2>
          <div class="texto">
            <h3>{{ category.dropdown.title }}</h3>
            <div v-html="category.dropdown.text"></div>
          </div>
        </article>
      </div>
    </section>

    <section class="banner-hablamos">
      <div class="container row-start">
        <img :src="$t('our_brands.contact_banner.img')" alt="" />
        <div class="texto">
          <h2 class="main-title">
            {{ $t("our_brands.contact_banner.title") }}
          </h2>
          <div v-html="$t('our_brands.contact_banner.text')"></div>
          <router-link
            class="boton-verde"
            :to="$t('paths.contacto') + '#formulario'"
          >
            {{ $t("our_brands.contact_banner.btn_text") }}
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "nuestras-marcas",
  methods: {
    showtext(event) {
      if (event.target.classList.contains("show")) {
        event.target.nextElementSibling.classList.remove("show");
        event.target.classList.remove("show");
      } else {
        event.target.nextElementSibling.classList.add("show");
        event.target.classList.add("show");
      }
    },
  },
};
</script>
