<template>
  <div class="wrapper -home -fondo-campo">
    <section class="slider-intro">
      <slick ref="slick" :options="slickOptions">
        <div>
          <article class="slide1 row-center">

            <p class="title">{{ $t("home.intro.juntos") }}</p>
          </article>
        </div>

        <div>
          <article class="ian50 row-center">
            <router-link class="enlace-cover" to="/"></router-link>
          </article>
        </div>

        <div>
          <article class="banner-grupo-ian -color row-center">
            <router-link class="enlace-cover" :to="$t('paths.quienes-somos')">
            </router-link>
            <p class="title">{{ $t("home.intro.juntos") }}</p>
            <nav class="marcas row-center">
              <router-link :to="$t('paths.empresas-grupo')"><img src="/img/logo-ian.png" alt="icon IAN" /></router-link>
              <a target="_blank" :href="$t('paths.carretilla-info')"><img src="/img/logo-carretilla.png"
                  alt="icon Carretilla" /></a>
              <router-link :to="'/' + $t('paths.empresas-grupo') + '/comar'"><img src="/img/logo-comar.png"
                  alt="icon Comar" /></router-link>
              <router-link :to="'/' + $t('paths.empresas-grupo') + '/taboada'"><img src="/img/logo-taboada.png"
                  alt="icon Taboada" /></router-link>
              <router-link :to="'/' + $t('paths.empresas-grupo') + '/norenense'"><img src="/img/logo-norenense.jpg"
                  alt="icon Norenense" /></router-link>
              <a href="https://www.interoliva.com/" target="_blank"><img src="/img/logo-interoliva.svg"
                  alt="icon interoliva" /></a>
            </nav>
            <video autoplay="true" loop="true" playsinline="true" muted poster="/img/poster.jpg">
              <source src="/img/video-home.mp4" type="video/mp4" />
              <source src="/img/video-home.mov" type="video/mov" />
            </video>
          </article>
        </div>

        <div>
          <article class="carretilla50 row-center">
            <a class="enlace-cover" :href="$t('paths.carretilla-info')" target="_blank">
            </a>
          </article>
        </div>

<!--         <div>
          <article class="a-tu-lado row-center">
            <router-link class="enlace-cover" :to="$t('paths.nuestras-marcas')">
            </router-link>
            <p class="title">{{ $t("home.intro.atulado") }}</p>
          </article>
        </div> -->

        <div>
          <article class="apostamos-calidad row-center">
            <router-link class="enlace-cover" :to="$t('paths.innovacion')">
            </router-link>
            <p class="title">{{ $t("home.intro.apostamos") }}</p>
          </article>
        </div>

        <div>
          <article class="rsc row-center" :style="`background-image: url(${$t('home.intro.rsc_background')});`">
            <router-link class="enlace-cover" :to="$t('paths.compromiso-ian')">
            </router-link>
            <p class="title">{{ $t("home.intro.rsc") }}</p>
          </article>
        </div>

        <div>
          <article class="interoliva row-center">
            <a class="enlace-cover" :href="$t('paths.interoliva')" target="_blank">
            </a>
            <img class="logo" src="/img/logos/interoliva.png" alt="logo interoliva" />
            <p class="title">{{ $t("home.intro.interoliva") }}</p>
          </article>
        </div>
      </slick>
    </section>
    <acceso-productos></acceso-productos>

    <section class="banner-acceso -invert -gray">
      <div class="container row-start">
        <a href="https://carretilla.info/" target="_blank" class="enlace-cover"></a>
        <a href="https://carretilla.info/" target="_blank" class="link-arrow -black waypoints" effect="animate__fadeInUp"
          v-html="$t('home.banner_our_brand.title')"></a>
        <img :src="$t('home.banner_our_brand.img')" alt="Carretilla" effect="animate__fadeInUp" class="waypoints" />
      </div>
    </section>

    <section class="banner-acceso">
      <div class="container row-start">
        <router-link :to="$t('paths.calidad–y-seguridad-alimentaria')" class="enlace-cover"></router-link>
        <img :src="$t('home.banner_calidad.img')" alt="Carretilla" class="waypoints" effect="animate__fadeInUp" />
        <a href="https://www.carretilla.info/" target="_blank" class="link-arrow -black waypoints"
          effect="animate__fadeInUp" v-html="$t('home.banner_calidad.title')"></a>
      </div>
    </section>

    <proyectos-home></proyectos-home>

    <section class="banner-foodservice">
      <div class="container row-start">
        <div class="foodservice-left column-start">
          <a target="_blank" class="link-arrow -black waypoints" href="https://rfh.grupoian.com/"
            effect="animate__fadeInUp" v-html="$t('home.banner_foodservice.title')"></a>
          <img :src="$t('home.banner_foodservice.img')" alt="Canal Foodservice" class="waypoints"
            effect="animate__fadeInUp" />
        </div>
        <div class="banner-exportacion waypoints" effect="animate__fadeInUp">
          <h2 v-html="$t('home.banner_foodservice.exportacion.titulo')"></h2>
          <p v-html="$t('home.banner_foodservice.exportacion.cuerpo')"></p>
          <router-link class="white-arrow" :to="{ name: 'exportacion' }"></router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Slick from "vue-slick";
import accesoProductos from "@/components/acceso-productos";
import proyectoshome from "@/components/proyectos-home";

export default {
  name: "Home",
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        autoplay: false,
        speed: 2700,
        autoplaySpeed: 2700,
      },
    };
  },
  components: {
    Slick,
    "proyectos-home": proyectoshome,
    "acceso-productos": accesoProductos,
  },
};
</script>
