<template>
  <div class="wrapper -empresas-del-grupo padding-menu -fondo-campo">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link to="/" style="pointer-events: none;">{{
          $t("navbar_menu.group_ian.title")
        }}</router-link>
        <router-link :to="$route.path">{{
          $t("navbar_menu.group_ian.sub_groups.empresas_grupo.title")
        }}</router-link>
      </div>
    </div>

    <section class="intro-page pd-0">
      <div class="container">
        <h1>{{ $t("companies_group.title") }}</h1>
        <div class="green-box" v-html="$t('companies_group.main_banner.text')"></div>
      </div>
      <div class="contenedor-marcas">
        <div class="marcas-scroll">
          <nav class="row-between container">
            <router-link v-for="(company, key) in $t('companies_group.companies')" :key="key"
              :active="selected == company.id" :to="'/' + $t('paths.empresas-grupo') + '/' + company.id">
              <img :src="`/img/empresa-${company.id == 'comar' ? 'comaro' : company.id
                }.svg`
                " :alt="`logo ${company.id}`" />
            </router-link>
          </nav>
        </div>
      </div>
    </section>

    <template v-for="(company, key) in $t('companies_group.companies')">
      <div v-if="selected == company.id" :key="key">
        <section class="banner-empresa-grupo">
          <div class="container row-start">
            <div class="texto">
              <h2 class="main-title">{{ company.history_banner.title }}</h2>
              <div v-html="company.history_banner.text"></div>
              <router-link :to="'/' +
                $t('paths.certificaciones') +
                '/' +
                company.history_banner.certifications_slug
                " class="arrow-link">
                {{ company.history_banner.btn_text }}
              </router-link>
            </div>
            <img :src="company.history_banner.img1" alt="" />
          </div>
        </section>

        <section class="imagenes-empresa">
          <div class="container row-end">
            <img :src="company.history_banner.img2" alt="" />
            <img :src="company.history_banner.img3" alt="" />
          </div>
        </section>

        <section class="banner-empresa" v-if="company.other_history_banner">
          <div class="container" :class="company.id == 'ian' ? 'row-start' : 'row-end'">
            <img :src="company.other_history_banner.img" alt="" :style="company.id == 'ian' ? 'right:0' : 'left:0'"/>
            <div class="texto" :style="company.id == 'ian' ? 'padding-right: 30px;' : 'padding-left: 30px;'">
              <div v-html="company.other_history_banner.text"></div>
              <div v-if="show_more" v-html="company.other_history_banner.tetx_view_more"></div>
              <a v-if="company.other_history_banner.tetx_view_more" :class="!show_more ? 'flecha-green-down' : 'flecha-green-down up'
                " @click="show_more = !show_more">
                {{
                  !show_more
                  ? company.other_history_banner.btn_text_more
                  : company.other_history_banner.btn_text_less
                }}
              </a>
              <a v-if="company.other_history_banner.btn_redirect" :href="company.other_history_banner.btn_redirect.redirect" target="_blank" class="arrow-link">
                {{ company.other_history_banner.btn_redirect.text }}
              </a>
            </div>
          </div>
        </section>

        <section class="banner-norenense" v-if="company.video_banner">
          <div class="container column-center">
            <h2 class="main-title">{{ company.video_banner.title }}</h2>
            <img src="/img/play.svg" alt="" />
            <a :href="company.video_banner.link" target="_blank" class="enlace-cover"></a>
          </div>
        </section>

        <section class="banner-seguridad" :style="`background:url(/img/background-somos-${company.history_banner.certifications_slug}.jpg) center/cover no-repeat`
          ">
          <div class="container row-end">
            <div class="texto">
              <h2 class="main-title">{{ company.company_banner.title }}</h2>
              <div v-html="company.company_banner.text"></div>
            </div>
            <img :src="company.company_banner.img1" alt="Innovación y Calidad" class="seguridad-1" />
            <img :src="company.company_banner.img2" alt="Innovación y Calidad" class="seguridad-2" />
            <img :src="company.company_banner.img3" alt="Innovación y Calidad" class="seguridad-3" />
          </div>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "calidad-e-innovacion",
  mounted() {
    if (this.$route.params.id) {
      this.selected = this.$route.params.id;
    }
    if (this.$route.name == "empresas-grupo-norenense") {
      this.selected = "norenense";
    }
  },
  data: () => ({
    selected: "ian",
    show_more: false,
  }),
  methods: {
    set(el) {
      this.selected = el;
    },
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.selected = this.$route.params.id;
      }
      if (this.$route.name == "empresas-grupo-norenense") {
        this.selected = "norenense";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-seguridad .container {
  align-items: flex-end;
  padding-bottom: 10px;
  padding-top: 380px;
}

.banner-seguridad {
  .texto {
    max-width: 305px;

    h2 {
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .seguridad-1 {
    position: absolute;
  }

  .seguridad-2 {
    left: 443px;
    top: -60px;
  }

  .seguridad-3 {
    left: 443px;
    top: 277px;
  }
}

.intro-page .container {
  position: relative;

  h2 {
    margin-bottom: 20px;
  }
}

.intro-page .contenedor-marcas a {
  border-radius: 0;
  padding: 0;
  width: 125px;
  height: 125px;
  background: transparent;

  img {
    width: 100%;
  }
}

.intro-page .green-box {
  position: absolute;
  left: 0;
  top: 100px;
  max-width: 800px;
  padding: 15px;
  z-index: 3;
}

.intro-page .contenedor-marcas a[active="true"] {
  &::before {
    z-index: -1;
  }
}

@media screen and (max-width: 1250px) {
  .intro-page .contenedor-marcas a {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 1100px) {
  .intro-page .green-box {
    bottom: auto;
  }
}

@media screen and (max-width: 980px) {
  .banner-seguridad .container {
    padding-top: 200px;
  }
}

@media screen and (max-width: 980px) {
  .banner-seguridad .container {
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    flex-wrap: wrap;
  }

  .banner-seguridad {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 900px) {
  .intro-page {
    .green-box {
      right: 30px;
      top: 100px;
    }

    .contenedor-marcas a[active="true"]::before {
      display: none;
    }

    .contenedor-marcas .marcas-scroll {
      overflow-x: scroll;
    }

    .contenedor-marcas nav.container {
      flex-wrap: nowrap;
      padding: 0;
    }

    .contenedor-marcas a {
      margin: 0;
      padding: 10px;
      width: 25%;

      &[active="true"] {
        background-color: #80b82e;
      }
    }
  }
}
</style>
