<template>
    <div class="wrapper -proyectos-ayudas padding-menu -fondo-campo">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link :to="$t('paths.calidad–y-seguridad-alimentaria')" style="pointer-events: none;">
                    {{ $t('navbar_menu.quality_innovation.title') }}
                </router-link>
                <router-link :to="$route.path">
                    {{ $t('projects_grants_subsidies.title') }}
                </router-link>
            </div>
        </div>
        <section class="intro-page">
            <div class="container">
                <h1 class="waypoints">{{ $t('projects_grants_subsidies.title') }}</h1>
                <img :src="$t('projects_grants_subsidies.main_banner.img')" class="waypoints">
                <div class="green-box" v-html="$t('projects_grants_subsidies.main_banner.text')">
                </div>
            </div>
        </section>
        <div class="container -paragraph" v-html="$t('projects_grants_subsidies.main_banner.sub_text')"></div>
        <section class="container contenedor-proyectos" v-show="data">
            <proyecto :key="key" :data="n" v-for="(n, key) in data"></proyecto>
        </section>
    </div>
</template>

<script>
import proyecto from '@/components/proyecto-el';
import { mapActions } from 'vuex';

export default {
    name: 'proyectos',
    mounted() {
        let lang = parseInt(this.$t(`langs.${this.$route.params.lang}`))
        this.requestProyectos(lang).then(r => {
            this.data = r;
        })
    },
    data: () => ({
        data: null
    }),
    methods: {
        ...mapActions(['requestProyectos'])
    },
    components: {
        'proyecto': proyecto
    },
    watch: {
        "$route.params.lang": function () {
            let langID = parseInt(this.$t(`langs.${this.$route.params.lang}`))
            this.requestProyectos(langID).then(r => {
                this.data = r;
            })
        }
    }
}
</script>